<template>
  <div class="comimput-body">
    <el-input v-model.trim="input" v-track="{ event: 'click', route: $route, isButton: false, log: log }" ref="input"
      size="small" @focus="getfocus" @input="getinput" @blur="getblur" maxlength="50" style="max-width: 485px;"
      :placeholder="placeholder"></el-input>
    <div class="slice-box" v-show="showDown">
      <ul class="slice-box-top">
        <div v-if="isCommunity">
          <li v-for="option in list" :key="option.id" @click="select(option)" class="slice">
            <template v-if="!showHistory">
              <span class="parentheses" style="display: inline-block;position: relative;width: 100%">

                <span v-html="option.lpNameHighlight">
                </span>
                <span v-if="option.countyFullName">{{ option.countyFullName }}</span>
                <!-- <span v-html="hightColor(option.communityName)"> </span>
                <span v-if="option.lpAliasList">
                  ( <span class="parentheses" v-html="hightColor(option.lpAliasList)"></span>)
                </span>
                <span v-if="option.countyFullName" v-html="hightColor(option.countyFullName)"></span> -->
              </span>
              <div v-if="option.isSHOWbottomTitle" @click="clickNoComunity" style="color: #0077FF;">{{
                option.title
              }}</div>
            </template>
            <template v-if="showHistory">
              <span style="display: inline-block;position: relative;width: 100%">
                <Icon type="md-time" />
                <span> {{ option.communityName_lpAliasList_countyFullName }}</span>
                <!-- <span> {{ option.communityName }}</span>
                <span v-if="option.lpAliasList" class="parentheses">({{ option.lpAliasList }})</span>
                <span v-if="option.countyFullName">-{{ option.countyFullName }}</span> -->

                <Icon @click.stop="closeCommunityAddress(option)"
                  style='font-size: 22px;position: absolute;right: 0;top: 7px;' type="ios-close" />
              </span>
            </template>
          </li>
        </div>
        <div v-if="!isCommunity">
          <li v-for="option in list" :key="option.id" @click="selectcertAddress(option)" class="slice">
            <template v-if="!showHistory">
              <span style="display: inline-block;position: relative;width: 100%">
                <!-- <div style="color: #0077FF;">
                  {{ option.certAddress }}
                </div> -->

                <!-- <div v-html="hightColor(option.certAddress_title)" :title="option.certAddress"> -->
                <!-- <div v-html="hightColor(option.certAddress)" :title="option.certAddress"> -->
                <div v-html="option.certAddressHighlight" :title="option.certAddress">
                </div>
                <div class="wasp" style="  color: #BFBFBF;">
                  <span>{{ option.lpName }}</span>
                  <span v-if="option.lpAliasList">({{ option.lpAliasList }})</span>
                </div>
              </span>
            </template>
            <template v-if="showHistory">
              <div class="certAddress-slice-box">
                <Icon type="md-time" style="padding-top: 8px; font-size: 16px; margin-right: 5px;" />
                <div>
                  <div style="color: #737373; width: 390px;" :title="option.certAddress">
                    <!-- {{ option.certAddress_title }} -->
                    {{ option.certAddress }}
                  </div>
                  <div class="wasp" style="color: #BFBFBF;">
                    <span>{{ option.lpName }}</span>
                    <span v-if="option.lpAliasList">({{ option.lpAliasList }})</span>
                  </div>
                </div>

                <Icon @click.stop="closeCtcertAddress(option)"
                  style='font-size: 22px; padding-top: 4px; margin-left: 10px;' type="ios-close" />
              </div>
            </template>
          </li>
        </div>
      </ul>
      <!-- <div class="no-box">找不到小区</div> -->
      <div v-loading="loading" customClass="loading-class"    element-loading-spinner="el-icon-loading" v-show="!showHistory && loading" style="height: 150px;"></div>
      <!-- <div v-loading="loading" customClass="loading-class"    element-loading-spinner="el-icon-loading"  style="height: 150px;"></div> -->
      <div class="no-box" @click="clickNocreat" v-track="{ event: 'click', route: $route, isButton: true, log: '找不到小区' }"
        v-show="!showHistory && isCommunity">
        <span style="color: #737373;">找不到你想要的小区，</span>
        手动提交估价
        <Icon type="ios-arrow-forward" />
      </div>
      <div class="no-box" @click="clickNocreat" v-track="{ event: 'click', route: $route, isButton: true, log: '找不到房屋' }"
        v-show="!showHistory && !isCommunity">
        <span style="color: #737373;"> 找不到你想要的房屋，</span>
        手动提交估价
        <Icon type="ios-arrow-forward" />
      </div>

    </div>
  </div>
</template>

<script>
import { communitySelectCondition, certSelectCondition } from '@/api/pledge'

export default {
  name: 'ComInput',
  props: {
    isCommunity: {//是否是小区搜索
      type: Boolean,
      default: true,
    },
    faInput: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // input: this.faInput,
      isClickList: false,//是否点击下拉列表
      input: '',
      loading: false,
      timer: '',
      showHistory: false,
      cityObj: JSON.parse(sessionStorage.getItem('cityObj')),
      list: [

      ],
      historyList: [],
      showDown: false
    };
  },
  mounted() {
    this.cityObj = JSON.parse(sessionStorage.getItem('cityObj'))
    this.input = this.faInput
  },
  watch: {
    input(val, oldval) {

      if (val == '') {
        // this.showHistory = true
        this.$emit('basedata', null)

      }
    },
    faInput(val) {
      if (val !== this.input) {
        this.input = val

      }
    }
  },
  computed: {
    log() {
      let test = ''
      if (this.isCommunity) {
        test = '搜索-小区名称'
      } else {
        test = '搜索-房屋名称'
      }
      return test
    },
    historyKey() {
      let key = ''
      if (this.isCommunity) {
        key = 'searchHistory_v1'
      } else {
        key = 'searchCertAddressHistory_v1'
      }
      return key
    },
  },
  methods: {
    hightColor(val) {
      if (!this.input) {
        return val
      } else {
        return val.replaceAll(this.input, `<span style="color:#0077FF">${this.input}</span>`)


      }
    },
    clickNocreat() {//点击找不到地址
      this.$emit('noLook', this.input)

    },
    select(item) {
      this.isClickList = true

      this.input = item.communityName
      this.$emit('basedata', item)

      this.setcertAddressHistory(item)
    },
    selectcertAddress(item) {
      this.isClickList = true
      this.input = item.certAddress
      this.$emit('basedata', item)
      this.setcertAddressHistory(item)
      this.showDown = false;
    },
    getChange(query) {

    },
    checkCityAuto(){//判断城市权限
    let  cityList = JSON.parse(localStorage.getItem('userAutoCityList'))
return cityList.length>0

    },
    async getinput(query) {
      // this.list = []
      if (query == '') {
        this.list = []
        this.getSelectHistory()
      } else {
        this.showHistory = false
        this.list = []
      }
      if (query.length < 2) {
        return
      }
      if (!this.checkCityAuto()) {
        return
      }
      // if (this.list.length > 0) {
      //   return
      // }
      if (query !== '') {
        this.list = []
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.cityObj = JSON.parse(sessionStorage.getItem('cityObj'))
        let { cityCode } = this.cityObj||''
        this.timer = setTimeout(async () => {
          let params = {
            condition: query,
            cityCode
          }
          let data = null
          if (this.isCommunity) {
            this.loading = true;
            data = await communitySelectCondition(params)
            this.loading = false;

            this.communityData(data)
          } else {
            this.loading = true;
            data = await certSelectCondition(params)

            this.loading = false;

            this.certAddressData(data)
          }
        }, 300)
      }
    },
    communityData(data) {//根据小区搜索处理数据
      if (data) {
        this.list = []
        data.map(item => {
          let obj = {}
          // let texts = ''
          // if (item.lpAliasList != null) {
          //   if (item.lpAliasList.length > 24) {
          //     texts = item.lpAliasList.substring(0, 24) + '...' // 进行数字截取或slice截取超过长度时以...表示
          //   } else {
          //     texts = item.lpAliasList
          //   }
          // }
          if (item.lpAliasList) {
            item.lpAliasList = `(${item.lpAliasList})`
          } else {
            item.lpAliasList = ''
          }
          if (item.countyFullName) {
            item.countyFullName = '-' + `${item.countyFullName}`
          } else {
            item.countyFullName = ''
          }
          item.communityName_lpAliasList_countyFullName = item.communityName + ' ' + item.lpAliasList + ' ' + item.countyFullName

          let texts = ''
          if (item.communityName_lpAliasList_countyFullName != null) {
            if (item.communityName_lpAliasList_countyFullName.length > 26) {
              texts = item.communityName_lpAliasList_countyFullName.substring(0, 26) + '...' // 进行数字截取或slice截取超过长度时以...表示
            } else {
              texts = item.communityName_lpAliasList_countyFullName
            }
          }
          item.communityName_lpAliasList_countyFullName = texts
          obj = item
          obj.value = item.communityName
          obj.label = item.communityName
          return obj
        })
        this.list = data
      }
    },
    certAddressData(data) {//根据地址搜索处理数据
      if (data) {
        this.list = []
        data.map(item => {
          let obj = {}
          let texts = ''
          if (item.certAddress != null) {
            if (item.certAddress.length > 26) {
              texts = item.certAddress.substring(0, 26) + '...' // 进行数字截取或slice截取超过长度时以...表示
            } else {
              texts = item.certAddress
            }
          }
          item.certAddress_title = texts
          obj = item
          // obj.label = item.certAddress
          obj.label = item.certAddress
          obj.value = item.certAddress
          return obj
        })
        this.list = data
      }
    },
    setcertAddressHistory(item) {//设置地址搜索历史
      let obj = item
      let key = this.historyKey
      let history = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(
        key)) : []
      history.unshift({
        value: obj.value,
        value_communityCode: obj.value + obj.communityCode,
        ...obj
      })
      history = this.uniqueFunc(history, 'value_communityCode')
      history = history.slice(0, 5)
      localStorage.setItem(key, JSON.stringify(history))
    },
    //获取搜索历史
    async getSelectHistory() {

      let key = this.historyKey
      let history = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(
        key)) : []
      // history = [...new Set(history)];
      history = this.uniqueFunc(history, 'value_communityCode')
      this.list = history.slice(0, 5)
      this.showHistory = true
    },

    closeCommunityAddress(obj) {
      let key = this.historyKey
      let history = this.list.filter((item) => {
        return obj.id !== item.id
      })

      this.list = history
      localStorage.setItem(key, JSON.stringify(history))
      this.$refs.input.focus({
        cursor: 'end'
      });
      setTimeout(() => {
        this.showDown = true;
      }, 300)

    },
    closeCtcertAddress(obj) {//地址估价-删除历史
      let key = this.historyKey
      let history = this.list.filter((item) => {
        return obj.houseCode !== item.houseCode
      })

      this.list = history
      localStorage.setItem(key, JSON.stringify(history))
      this.$refs.input.focus({
        cursor: 'end'
      });
      setTimeout(() => {
        this.showDown = true;
      }, 300)
    },
    uniqueFunc(arr, value) { //数组去重
      const res = new Map();
      return arr.filter((item) => !res.has(item[value]) && res.set(item[value], 1));
    },
    getfocus() {//获取焦点
      this.isClickList = false
      this.showDown = true;
      if (this.input == '') {
        this.getSelectHistory()
      } else {
        this.getinput(this.input)
      }

    },
    getblur() {
      setTimeout(() => {
        this.showDown = false;





      }, 300)

      if (this.input == '') {
        return
      } else {
          this.clearInput()
      }
    },
    clearInput() {
      setTimeout(() => {
   if (this.isClickList) {//点击下拉列表
    return
   }

        let result = this.list.some(item => item.value == this.input) //判断手动输入的地址是否在系统内
        if (!result) {
          this.input = ''
        } else {
          this.list.map(item => {
            if (item.value == this.input) {
              this.$emit('basedata', item)

            }
          })
        }

      }, 350)

    },

  },
};
</script>

<style lang="scss" scoped>
.loading-class{
width: 100px;

}
.comimput-body /deep/ .el-input__inner {
  padding-right: 90px;

}

.wasp {
  //超出隐藏
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.certAddress-slice-box {
  display: flex;
  // align-items: center;
}

.slice-box {

  width: 485px;
  // max-height: 200px;
  // overflow: auto;
  margin: 5px 0;
  // padding: 5px 0;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  position: absolute;
  z-index: 11900;
  max-height: 358px;
  overflow: auto;

  .slice-box-top {
    // max-height: 180px;
    // overflow: auto;

    .parentheses {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: -webkit-box;
    }
  }

  .no-box {

    color: #0077FF;
    padding-left: 14px;
    margin-top: 24px;
    margin-bottom: 30px;
    display: block;
    cursor: pointer;
  }

  .slice {
    cursor: pointer;
    padding: 0 16px;

    &:hover {
      background-color: #F5F7FA;

    }

  }
}
/deep/ .el-icon-loading{

font-size: 30px;
}

</style>
<style>
.hightColor {
  color: #0077FF;
}

</style>